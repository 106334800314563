<template>
  <div>
    <Header/> 
    <div class="content_text">
      <!-- <router-link :to="{path:'/'}">
        <img class="content_img_1" src="../../assets/content/logo_s.png">
      </router-link> -->
        <img class="content_img_2_3" :src="logo">
        <div calss="content_study">{{logo_title}}</div>
    </div>
    
    <div class="svsv">
        <div class="topic-item" style="width: 100%;height: 100.8px;">
            <img :src="topic_logo" style="width:100%;height:100%;">
        </div>

        <div v-if="!id">
          <div v-for="items in lesson_item_list" :key="items">
              <router-link :to="{path:'/topic/'+items.id}">
                <div class="lesson-item">
                    <div class="lesson-item_1">
                        <img :src="items.url">
                    </div>
                    <div class="lesson-item_2">
                        <p class="pa_1">{{items.name}}</p>
                        <p class="pa_2">{{items.title}}</p>
                        <p class="pa_3">{{items.text}}</p>
                    </div>
                </div>
              </router-link>
          </div>
        </div>
        <div v-else>
          <div style="height:5vh;"></div>
          <div class="wux-padding--15">
              <img src="../../assets/topic/text_logo.jpg">
              <div>此課程內容只開放給相關線下付費課會員</div>
              <div class="topic_kc">
                <button class="round-btn_i un_button">激活VIP功能</button>
                <div style="margin-top: 10px;"><p>已付費用戶或團隊用戶</p><p>補充資料以開啟VIP功能</p></div>
              </div>
          </div>
        </div>
      <div style="width:100%;height:90px;"></div>
    </div>

    <Footer />
  </div>
</template>

<style>
p{
  margin:0;
}
.un_button{
  width:100%;
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-left: 14px;
  padding-right: 14px;
  box-sizing: border-box;
  font-size: 18px;
  text-align: center;
  text-decoration: none;
  line-height: 2.55555556;
  border-radius: 5px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  color: #000;
  background-color: #f8f8f8;
  cursor: pointer;
}
.round-btn_i{
  display: block;
  color: #fff;
  line-height: 130%;
  font-size: 16px;
  padding: 7px 20px;
  border: 1px solid #fff;
  border-radius: 999999px;
  background-color: initial;
  -webkit-transition: all .3s cubic-bezier(.215,.61,.355,1);
  transition: all .3s cubic-bezier(.215,.61,.355,1);
}
.topic_kc{
  margin: 30px;
  text-align: center;
}
.wux-padding--15{
  padding: 14px!important;
  text-align: center!important;
  color:#fff;
}
.lesson-item_2 p{
  margin: 0 0 0 0;
}
.lesson-item_2{
  width: 65%;
  height: auto;
  margin-left: 8px;
  margin-top: 4px;
  margin-bottom: 5px;
  margin-right: 5px;
}
.lesson-item_1{
  width: 35%;
  height: auto;
}
.lesson-item_1 img{
  width: 100%;
  height:100%;
}
.pa_1{
  font-family: Helvetica Neue;
    font-weight: 300;
    font-size: 12px;
    text-align: left;
    color: rgba(0,0,0,.7);
}
.pa_2{
  font-family: Helvetica Neue;
    font-weight: 500;
    font-size: 20px;
    text-align: left;
    color: rgba(0,0,0,.7);
}
.pa_3{
    font-family: Helvetica Neue;
    font-weight: 500;
    font-size: 14px;
    text-align: left;
    color: rgba(0,0,0,.7);
}
.lesson-item{
  height: auto;
  border-bottom: 1px solid #fff;
  display: flex;
  position: relative;
  min-height: 70px;
}
    .topic-item {
        margin: 0;
        border-bottom: 1px solid #fff;
        border-top: 1px solid #fff;
    }
  .svsv{
    height: auto;
    width: 100%;
    float:left;
    overflow: hidden auto;
  }

  .content_study{
    font-family: Helvetica Neue;
    font-weight: 400;
    font-size: 20px;
    text-align: left;
    color: #fff;
  }
  .content_text{
    padding-left: 17px;
    height: 76px;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    font-family: Helvetica Neue;
    font-weight: 400;
    font-size: 20px;
    text-align: left;
    color: #fff;
  }
  .content_img_1{
    margin-right: 13px;
    width: 60px;
    height: 55px;
  }
  .content_img_2_3{
    width: 50px;
    height: 42px;
    margin-right: 8px;
  }
</style>
<script>
import Header from "../Header";
export default {
  components:{Header},
  data(){
    return {
      logo:'',
      logo_title:'',
      topic_logo:'',
      // id 值 主要用於通過搜索頁的課程進來的頁面
      id:0,
      vip_status:0,
      }
  },
  created(){
  },
}
</script>

